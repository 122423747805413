:root {
    --bezier: 0.24, 0.74, 0.13, 1.24;
    --round-bottom: 0px;
    --round-top: 0px;
    --clip-bottom: 0px;
    --clip-top: 0px;
    --clip-lr: 0px;
}

.logoAndre {
    width: 130px;
}

.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-ref {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 600ms;
}

.title-ref > h1 {
    color: #000;
}

.navbar {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-color: rgb(0, 0, 30);
    padding-top: 20px;
    z-index: 1;
    height: 100vh;
    transition: 800ms cubic-bezier(var(--bezier));
    clip-path: inset(
        var(--clip-top, 0px) var(--clip-lr, 0px) var(--clip-bottom, 100dvh) var(--clip-lr, 0px) round max(0px, var(--round-top, 0px)) max(0px, var(--round-top, 0px)) max(0px, var(--round-bottom, 0px))
            max(0px, var(--round-bottom, 0px))
    );
}

.padding {
    width: calc(100vw - 200px);
}

.normalWidth {
    width: 100vw;
}

.navHead,
.title-ref,
.mobileNav,
.navbar > h1 {
    z-index: 1;
}

.splineScene {
    transition: 400ms;
    cursor: grab;
    overflow: clip;
}

.splineContainer {
    position: fixed;
    left: 0;
    top: 0;
}

.splineSceneLeft {
    left: -120px;
}

/* .splineScene > canvas {
    width: 100% !important;
    height: 100% !important;
} */

.navHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 100px);
    padding-inline: 50px;
    color: #fff;
}

.nav-cta {
    border-radius: 40px;
    cursor: pointer;
}

.nav-cta-background {
    padding: 10px 20px;
    background-color: hsla(0, 0%, 0%, 0.2);
    transition: 400ms;
}

.nav-cta-background-off {
    background-color: hsla(0, 0%, 0%, 0);
    transition: 400ms;
}

.nav-pages {
    display: flex;
    align-items: center;
    gap: 80px;
}

.background {
    width: 100vw;
    height: 100vh;
}

.navHead:hover {
    --round-bottom: 70px;
    --clip-bottom: calc(100vh - 400px);
}

.title-home {
    position: fixed;
    left: 80px;
    bottom: 80px;
    color: #fff;
    user-select: none;
    pointer-events: none;
    transition: 600ms;
}

.pageContainer {
    display: flex;
    align-items: flex-start;
    transition: 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    bottom: 0;
    left: 0;
    position: fixed;
    height: 100vh;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 200px);
    margin: 0 auto;
    background-color: #fff;
    z-index: 0;
    padding-inline: 100px;
    padding-top: 480px;
    height: calc(100vh - 480px);
    overflow-y: scroll;
}

.footer {
    border-top: 1px solid hsla(0, 0%, 0%, 0.2);
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    margin-top: 50px;
}

.mobileNav {
    display: none;
}

@media screen and (max-width: 800px) {
    :root {
        --bezier: 0.24, 0.74, 0.13, 1.08;
    }
    .navHead {
        width: calc(100% - 40px);
        padding-inline: 20px;
    }

    .splineSceneLeft {
        left: 0px;
    }

    .title-home {
        left: 20px;
        bottom: 100px;
    }

    .nav-pages {
        display: none;
    }

    .navHead > span {
        display: none;
    }

    .mobileNav {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        width: 100%;
        position: fixed;
        bottom: 20px;
        z-index: 10;
    }

    .mobileNav > div {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-left: 20px;
    }

    .title-ref {
        display: none !important;
    }

    .navbar {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .page {
        padding-inline: 20px;
        padding-top: 170px;
        width: calc(100vw - 40px);
        height: calc(100vh - 250px);
        margin-top: 80px;
    }

    .nav-cta {
        padding: 10px 20px;
        transition: 300ms;
    }

    .splineContainer {
        overflow: hidden;
    }

    .padding {
        margin-top: 20px;
        width: calc(100vw - 40px);
    }

    .pageContainer {
        padding-bottom: 50px;
        padding-top: 100px;
    }

    .footer {
        width: 100%;
    }

    .logoAndre {
        width: 50px;
    }

    .title-home {
        font-size: 1.2rem;
    }
}
