body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Neue Regular';
    src: url('./fonts/NeueMontreal-Regular.otf');
}

@font-face {
    font-family: 'Neue Bold';
    src: url('./fonts/NeueMontreal-Bold.otf');
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: 'Neue Bold'; */
    letter-spacing: 0.05rem;
    margin: 0;
}

p,
span,
a,
li {
    /* font-family: 'Neue Regular'; */
    line-height: 1.7rem;
    word-spacing: 0.15rem;
    letter-spacing: 0.05rem;
    margin: 0;
}

a {
    text-decoration: none;
    color: unset;
}
