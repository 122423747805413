.experiencesGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    gap: 80px;
}

.experiencesGrid > a {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.experiencesGrid > a > div > div > div {
    margin-bottom: 10px;
}

.experiencesGrid > a > div > h2 {
    margin-bottom: 10px;
}

.experiencesImg {
    max-width: 200px;
    width: 100%;
    background-color: hsl(0, 0%, 95%);
    padding: 20px;
    border-radius: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experiencesGrid > a > div > img {
    width: 100%;
}

.date {
    opacity: 0.4;
}

@media screen and (max-width: 800px) {
    .experiencesGrid {
        gap: 30px;
        grid-template-columns: 1fr;
        margin-top: 0;
    }
    .experiencesImg {
        max-width: 100px;
        width: 100%;
        border-radius: 10px;
    }
}
