.image > img {
    width: 200px;
    height: 80px;
    padding: 0 15px;
    object-fit: contain;
    transition: 300ms;
}

.image > img:last-of-type {
    padding-inline: 50px;
}

.inner {
    position: relative;
    width: 100vw;
    height: 5rem;
    padding-top: 65px;
    padding-bottom: 80px;
    margin-bottom: 50px;
    overflow: hidden;
}

.wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    height: 5rem;
}

section {
    display: flex;
    align-items: center;
    animation: swipe var(--speed) linear infinite backwards;
}

.less > img {
    width: 150px;
    filter: grayscale(100%);
}

.less > img:hover {
    filter: grayscale(0%);
    transition: 300ms;
}

.less-cont {
    display: flex;
    padding-inline: 100px;
    width: calc(100% - 200px);
    align-items: center;
    justify-content: space-between;
    margin-top: 124px;
    margin-bottom: 124px;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

@media screen and (max-width: 700px) {
    .inner {
        margin-top: 0px;
        width: 100%;
    }

    .image > img:last-of-type {
        padding-inline: 0px;
        padding-left: 20px;
    }

    .wrapper {
        height: 5rem;
    }
}
