.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
    gap: 50px;
    width: 100%;
    max-width: 1200px;
}

.email {
    font-size: 3rem;
}

.subHeadline {
    font-size: 1rem;
    line-height: 1.1rem;
    color: hsl(0, 0%, 70%);
    transition: 300ms;
}

.meetBtn {
    padding: 35px 50px;
    font-size: 1.2rem; /* Sposta questa riga sopra la dichiarazione di background */
    border-radius: 30px;
    color: #fff;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    transition: 300ms;
    text-align: center;
    max-width: 250px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
}

.meetBtn:hover {
    color: #fff;
    transition: 300ms;
}

.meetBtn > p {
    z-index: 2;
    font-weight: 500;
}

.meetBtn:hover .meetBtnBg {
    opacity: 1;
}

.iconContainer {
    position: relative;
}

.meetBtn:hover .gradienticon {
    opacity: 1;
    transition: 300ms;
}

.gradienticon {
    transition: 300ms;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: darken;
    background: linear-gradient(45deg, rgb(24, 159, 255) 0%, rgb(246, 134, 22) 53%, rgba(190, 0, 255, 1) 100%);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
}

.meetBtnBg {
    transition: 300ms;
    opacity: 0;
    color: #fff;
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    /* background: linear-gradient(45deg, rgb(24, 159, 255) 0%, rgb(246, 134, 22) 53%, rgba(190, 0, 255, 1) 100%); */
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    transform: translate(-50%, -50%);
}

.mainContact {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    text-align: center;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.mainContact > div > p {
    font-size: 1rem;
}
.mainContact > div > p > b {
    font-size: 1.5rem;
}

@media screen and (max-width: 800px) {
    .email {
        font-size: 1.4rem;
    }

    .contact {
        flex-direction: column;
    }

    .mainContact {
        align-items: flex-start;
        text-align: left;
    }

    .mainContact > p {
        font-size: 1.1rem;
        display: flex;
        flex-direction: column;
        gap: 0;
        line-height: 1.8rem;
    }

    .mainContact > p > b {
        font-size: 2.8rem;
        margin-bottom: -15px;
        line-height: 2.8rem;
        width: 200px;
    }

    .contact {
        gap: 20px;
    }
}
