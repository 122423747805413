.page_container {
    max-width: 900px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.page_frame {
    display: flex;
    align-items: flex-start;
    gap: 50px;
}

.page_frame > img {
    width: 250px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 20px;
    margin-top: -10px;
}

.socials {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}

.socials > a > img {
    width: 40px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 100%;
}

@media screen and (max-width: 800px) {
    .page_frame {
        flex-direction: column;
        gap: 10px;
    }

    .page_container {
        gap: 30px;
        height: auto;
        padding-bottom: 40px;
    }
}
